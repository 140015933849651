<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="用户组">
              <a-input v-model="queryParams.groupName" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="创建时间">
              <range-date
                @change="handleDateChange"
                ref="createTime"
              ></range-date>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button
          v-hasPermission="['group:add']"
          ghost
          type="primary"
          @click="add"
          >新增</a-button
        >
        <a-button v-hasPermission="['group:delete']" @click="batchDelete"
          >删除</a-button
        >
        <a-button v-hasPermission="['group:export']" @click="exprotExccel"
          >导出Excel</a-button
        >
        <a-radio-group
          @change="handleUserGroupChange"
          type="primary"
          v-model="queryParams.groupType"
          style="float: right"
        >
          <a-radio-button :value="2"> 服务工程师群组 </a-radio-button>
          <!-- <a-radio-button :value="1"> 社群 </a-radio-button> -->
          <!-- <a-radio-button :value="3"> 培训群组 </a-radio-button> -->
          <a-radio-button :value="4"> 一般群组 </a-radio-button>
        </a-radio-group>
      </div>
      <a-drawer
        :title="currentExpandGroup ? currentExpandGroup.groupName : ''"
        width="500"
        :closable="true"
        :visible="Boolean(currentExpandGroup)"
        @close="currentExpandGroup = null"
      >
        <div v-if="currentExpandGroup">

          <a-tag
            style="margin: 0 5px 5px"
            v-for="(data, index) in currentExpandGroup.userlist"
            :key="index"
          >
            {{ data.username }}
          </a-tag>

        </div>
      </a-drawer>

      <!-- 表格区域 -->
      <a-table
        bordered
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.groupId"
        :pagination="pagination"
        :dataSource="dataSource"
        :loading="loading"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :scroll="{ x: 900 }"
        @change="handleTableChange"
      >
        <template slot="userlist" slot-scope="list, record">
          <a-tag
            style="margin: 0 5px 5px"
            v-for="(data, index) in list"
            :key="index"
            v-show="index < 6"
          >
            {{ data.username }}
          </a-tag>
          <span
            style="white-space: nowrap; color: #3db6fc; cursor: pointer"
            v-if="list.length > 6"
            @click="currentExpandGroup = record"
          >
            {{
              currentExpandGroup && currentExpandGroup.groupId == record.groupId
                ? ""
                : "展开"
            }}
          </span>
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-icon
            v-hasPermission="['group:update']"
            type="edit"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改用户组"
          ></a-icon>
          &nbsp;
          <a-icon
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            @click="view(record)"
            title="查看"
          ></a-icon>
        </template>
      </a-table>
      <!-- 用户组信息查看 -->
      <user-group-info ref="userGroupInfo"> </user-group-info>
      <!-- 修改用户组 -->
      <user-group-edit @success="onEditOk" ref="userGroupEdit">
      </user-group-edit>
    </div>
  </a-card>
</template>

<script>
import RangeDate from "@/components/datetime/RangeDate";
import UserGroupInfo from "./UserGroupInfo";
import UserGroupEdit from "./UserGroupEdit";

export default {
  name: "UserGroup",
  components: { RangeDate, UserGroupInfo, UserGroupEdit },
  data() {
    return {
      advanced: false,
      queryParams: {
        groupName: "",
        groupType: 2,
        createTimeFrom: "",
        createTimeTo: "",
      },
      dataSource: [],
      filteredInfo: null,
      sortedInfo: null,
      paginationInfo: null,
      selectedRowKeys: [],
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      loading: false,
      currentExpandGroup: null,
    };
  },
  computed: {
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      let dataFilters = [];
      for (let indx in this.dataScope) {
        let obj = {
          text: this.dataScope[indx].valuee,
          value: this.dataScope[indx].keyy,
        };
        dataFilters.push(obj);
      }
      return [
        {
          title: "用户组",
          dataIndex: "groupName",
          width: 120,
        },
        {
          title: "用户列表",
          dataIndex: "userlist",
          scopedSlots: { customRender: "userlist" },
          width: 500,
        },
        {
          title: "类型",
          dataIndex: "groupType",
          customRender: (v) => {
            if (v == 1) {
              return "社群群组";
            }
            if (v == 2) {
              return "工程师群组";
            }
            if (v == 3) {
              return "培训组";
            }
          },
          width: 150,
        },
        {
          title: "描述",
          dataIndex: "description",
          width: 200,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          sorter: true,
          sortOrder: sortedInfo.columnKey === "createTime" && sortedInfo.order,
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 180,
        },
        {
          title: "修改时间",
          dataIndex: "updateTime",
          sorter: true,
          sortOrder: sortedInfo.columnKey === "modifyTime" && sortedInfo.order,
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" },
        },
      ];
    },
  },
  mounted() {
    this.fetch({ ...this.queryParams });
  },
  methods: {
    onEditOk() {
      this.fetch({ ...this.queryParams });
    },
    handleUserGroupChange() {
      this.fetch({ ...this.queryParams });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    add() {
      this.$refs.userGroupEdit.setForm(false);
    },
    view(record) {
      this.$refs.userGroupInfo.setData(record);
    },
    edit(record) {
      this.$refs.userGroupEdit.setForm(true, record);
    },
    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0];
        this.queryParams.createTimeTo = value[1];
      }
    },
    batchDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
        return;
      }
      let that = this;
      this.$confirm({
        title: "确定删除所选中的记录?",
        content: "当您点击确定按钮后，这些记录将会被彻底删除",
        centered: true,
        onOk() {
          let userGroupIds = that.selectedRowKeys.join(",");
          that.$delete("group/" + userGroupIds).then(() => {
            that.$message.success("删除成功");
            that.selectedRowKeys = [];
            that.search();
          });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    exprotExccel() {
      let { sortedInfo, filteredInfo } = this;
      let sortField, sortOrder;
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field;
        sortOrder = sortedInfo.order;
      }
      this.$export("group/excel", {
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo,
      });
    },
    search() {
      let { sortedInfo, filteredInfo } = this;
      let sortField, sortOrder;
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field;
        sortOrder = sortedInfo.order;
      }
      this.selectData({
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo,
      });
    },
    reset() {
      // 取消选中
      this.selectedRowKeys = [];
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent;
        this.paginationInfo.pageSize = this.pagination.defaultPageSize;
      }
      // 重置列过滤器规则
      this.filteredInfo = null;
      // 重置列排序规则
      this.sortedInfo = null;
      // 重置查询参数
      this.queryParams = {
        groupName: "",
        groupType: undefined,
        createTimeFrom: "",
        createTimeTo: "",
      };
      // 清空时间选择
      this.$refs.createTime.reset();
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      // 将这两个参数赋值给Vue data，用于后续使用
      this.paginationInfo = pagination;
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.queryParams,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.loadData(params);
    },
    selectData(params = {}) {
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.loadData(params);
    },
    loadData(params) {
      this.loading = true;
      this.$get("group", {
        ...params,
      }).then((r) => {
        this.loading = false;
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.pagination = pagination;

        if (data.rows == undefined) {
          this.dataSource = []
          return
        }
        let datasource = data.rows;

        let set = new Set();
        for (let item of datasource) {
          for (const id of item.userIdValueList) {
            set.add(id);
          }
        }
        let ids = [...set].join(",");

        this.$get(`user/list/simple/${ids}`).then((resp) => {
          let userlist = resp.data.data;

          for (let item of datasource) {
            item.userlist = [];
            for (const id of item.userIdValueList) {
              let one = userlist.find((item) => item.userId == id);
              if (one) {
                item.userlist.push(one);
              }
            }
          }

          this.dataSource = datasource;
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>
